import { Link } from "gatsby";
import React from "react";
import { Col, Row } from "react-bootstrap";

const CtaButtons = () => {
  return (
    <div className="ctabuttons">
      <Row className="justify-content-center">
        <Col sm="12" md="auto" className="d-grid">
          <a href="/verderon-product-deck-2022.pdf" target="_blank" className="btn btn-primary btn-lg">Download Brochure</a>
        </Col>
        <Col sm="12" md="auto" className="d-grid">
          <Link to="/demo/" className="btn btn-primary btn-lg">See Demo</Link>
        </Col>
        <Col sm="12" md="auto" className="d-grid">
          <Link to="/contact/" className="btn btn-primary btn-lg">Contact Us</Link>
        </Col>
      </Row>
    </div>
  );
}

export default CtaButtons;
